<template>
  <div>
    <div class="d-flex flex-row justify-content-between align-items-center">
      <h2 style="font-size: 24px; line-height: 28px" class="my-3">
        Средняя цена за подписчика по темам из отзывов
      </h2>
      <div
        v-if="!currentUser || !currentUser.services_subscriber"
        class="d-none d-xxl-flex flex-row justify-content-between align-items-center"
      >
        <h6 class="mr-3">Сервис доступен по подписке</h6>
        <b-button href="https://sub.easyprbot.com/" target="_blank" variant="yellow"
          >Купить подписку за 2 990 ₽ в месяц</b-button
        >
      </div>
    </div>

    <div class="hovering__pad p-4 mb-5 d-sm-none">
      <div class="mb-3 d-flex flex-row align-items-center">
        <img
          class="mr-3 d-inline-block"
          style="width: 35px; height: 23px"
          src="@main/assets/img/icons/ut_icon.png"
        />
        <h5 class="d-inline-block">Как пользоваться сервисом?</h5>
      </div>
      <b-embed
        type="iframe"
        aspect="16by9"
        src="https://www.youtube.com/embed/OC7VroDDeIk"
        allowfullscreen
      ></b-embed>
      <div class="mt-3 d-flex flex-row justify-content-center">
        <b-button class="my-3" variant="link">Свернуть</b-button>
      </div>
    </div>

    <div
      v-if="!currentUser || !currentUser.services_subscriber"
      class="hovering__pad p-4 mb-5 d-sm-none"
    >
      <h5 class="mb-4 text-center">Сервис доступен по подписке</h5>
      <b-button
        href="https://sub.easyprbot.com/"
        target="_blank"
        variant="yellow"
        class="w-100 py-2"
        >Купить подписку за 2 990 ₽ в месяц</b-button
      >
    </div>

    <b-button
      @click="showVideoModal = true"
      class="mb-4 d-none d-sm-block"
      size="lg"
      variant="outline-default"
    >
      <img
        class="d-inline-block mr-2"
        style="width: 34px; height: 23px"
        src="@main/assets/img/icons/ut_icon.png"
        alt=""
      />Как пользоваться сервисом?</b-button
    >
    <div class="hovering__pad pb-4 mb-5">
      <b-form inline class="py-4 px-4">
        <label class="fs-16">Данные из отзывов за:</label>
        <b-select
          class="ml-sm-3"
          size="md"
          v-model="filterParams.review__date_after"
          :options="reviewPeriods"
        ></b-select>
      </b-form>
      <b-table
        stacked="sm"
        :busy="!items || !items.length"
        :fields="fields"
        :items="items"
        striped
        bordered
        responsive
      >
        <template #table-busy>
          <div class="d-flex flex-row justify-content-center">
            <Loader class="m-5" />
          </div>
        </template>
        <template #head(avg_price)="data">
          <div class="d-flex align-items-start">
            <p class="d-inline-block" style="white-space: pre-wrap">{{ data.label }}</p>
            <div
              v-b-tooltip.html.hover
              :title="data.field.detail"
              id="tooltip-point"
              class="d-inline-block text-center ml-2 mt-1"
              style="
                min-width: 17px !important;
                cursor: pointer;
                font-size: 12px;
                line-height: 17px;
                width: 17px;
                height: 17px;
                background-color: #b4bdc1;
                border-radius: 100%;
              "
            >
              ?
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <Modal
      wrapClass="modal-md"
      title="Как пользоваться сервисом?"
      :show="showVideoModal"
      @hide="showVideoModal = false"
      ><div class="p-4" style="min-width: 750px">
        <b-embed
          type="iframe"
          aspect="16by9"
          src="https://www.youtube.com/embed/OC7VroDDeIk"
          allowfullscreen
        ></b-embed></div
    ></Modal>
  </div>
</template>

<script>
import Loader from '@main/components/Loader.vue';
import Modal from '@main/components/reusable/modals/Modal.vue';
import info from '@main/api/info';
import moment from 'moment';
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    Modal,
    Loader,
  },
  data: () => ({
    showVideoModal: false,
    filterParams: { review__date_after: null },
    items: null,
    reviewPeriods: [
      { text: 'За все время', value: null },
      { text: '1 месяц', value: moment().subtract(30, 'd').format('YYYY-MM-DD') },
      { text: '2 месяца', value: moment().subtract(60, 'd').format('YYYY-MM-DD') },
      { text: '3 месяца', value: moment().subtract(90, 'd').format('YYYY-MM-DD') },
      { text: '6 месяцев', value: moment().subtract(180, 'd').format('YYYY-MM-DD') },
      { text: '1 год', value: moment().subtract(360, 'd').format('YYYY-MM-DD') },
    ],
  }),
  watch: {
    filterParams: {
      deep: true,
      async handler() {
        await this.updateData();
      },
    },
  },
  computed: {
    ...mapState(['currentUser']),
    fields() {
      return [
        // A column that needs custom formatting
        {
          key: 'name',
          label: 'Тема',
        },
        {
          key: 'reviews',
          label: 'Количество реклам',
        },
        {
          key: 'avg_price',
          label: 'Средняя цена за подписчика\n(без радикальных значений)',
          detail: `Отзывы с большой ценой за подписчика сильно искажают выборку.<br><br>Для удобства, мы показываем цену с учетом этих отзывов, и без них.`,
          deckLabel:
            'Средняя цена за подписчика\n<p class="revprice__annotation">(с радикальными значениями)</p>',
          formatter: (value, key, item) => {
            const val = value && String(value.toFixed(1)).replaceAll('.', ',');
            return `${val || 0} ₽`;
          },
        },
        {
          key: 'avg_price_ext',
          label: 'Средняя цена за подписчика\n(с радикальными значениями)',
          deckLabel:
            'Средняя цена за подписчика\n<p class="revprice__annotation">(с радикальными значениями)</p>',
          formatter: (value, key, item) => {
            const val = value && String(value.toFixed(1)).replaceAll('.', ',');
            return `${val || 0} ₽`;
          },
        },
      ];
    },
  },
  methods: {
    ...mapMutations('payments', ['toggleSubscriptionPriceModal']),
    async updateData() {
      const res = await info.getThemesAggregation(this.filterParams);
      console.log(res);
      this.items = res.filter((val) => val.name !== 'Нет отзывов');
    },
  },
  async mounted() {
    await this.updateData();
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 330px) {
  ::v-deep .table.b-table.b-table-stacked-sm > tbody > tr > ::before {
    font-size: 11px !important;
  }
  ::v-deep .table.b-table.b-table-stacked-sm > tbody > tr > td > div {
    font-size: 13px !important;
  }
}
@media (max-width: 410px) {
  ::v-deep .table.b-table.b-table-stacked-sm > tbody > tr > ::before {
    font-size: 12.5px;
  }
  ::v-deep .table.b-table.b-table-stacked-sm > tbody > tr > td > div {
    font-size: 14px;
  }
}
@media (max-width: 578px) {
  ::v-deep .table.b-table.b-table-stacked-sm > tbody > tr > ::before {
    width: 63% !important;
    text-align: left !important;
    color: #6a7b83 !important;
    white-space: pre-wrap !important;
    font-weight: normal !important;
  }
  ::v-deep .table.b-table.b-table-stacked-sm > tbody > tr > td > div {
    width: calc(100% - 63%) !important;
    font-size: 15px;
    text-align: right !important;
  }
  ::v-deep .table.b-table.b-table-stacked-sm > tbody > tr > td {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
    vertical-align: middle !important;
  }
  ::v-deep .table.b-table.b-table-stacked-sm > tbody > tr {
    padding: 0.3rem !important;
  }
  ::v-deep .table-bordered td {
    border: none !important;
    vertical-align: middle !important;
  }
  ::v-deep .table-bordered tr {
    border-bottom: 1px solid #dee2e6 !important;
  }
}
::v-deep .table td {
  border-left: 1px solid #dee2e6;
  vertical-align: middle !important;
}
::v-deep .table th {
  white-space: pre-wrap !important;
  vertical-align: middle;
  font-weight: 500 !important;
  border-left: 1px solid #dee2e6;
}
::v-deep .tooltip-inner {
  position: absolute !important;
  min-width: 230px !important;
  font-size: 12.7px !important;
  background-color: white !important;
  text-align: left !important;
  color: black !important;
  opacity: 1 !important;
  border: 1px solid #3897f0;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1);
  border-radius: 5px;
}
</style>
